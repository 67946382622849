// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { LogLevel } from '@wellro/utils';

export const environment = {
  production: false,
  whatsAppNumber: '+94705302302',
  firebase: {
    apiKey: 'AIzaSyBdr3MDwCY1Ltz1er0SeJBCSCjPRPUvIOk',
    authDomain: 'wellro-wellness-staging.firebaseapp.com',
    projectId: 'wellro-wellness-staging',
    storageBucket: 'wellro-wellness-staging.appspot.com',
    messagingSenderId: '125729254720',
    appId: '1:125729254720:web:8f2f02bb8ebea82d70dea5',
    measurementId: 'G-5RF0ZP85J8',
  },
  sentry: {
    dsn:
      'https://f2a5d616b5204c70bb4a8e66f3c62bd3@o4504682974740480.ingest.sentry.io/4504693759279104',
    env: 'staging',
  },
  tenantId: 'customer-tevju',
  logLevel: LogLevel.DEBUG,
  hostUrl: 'https://staging.wellro.life',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
